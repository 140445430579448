const FeaturedContentSlideState = (state = [], action) => {
  switch (action.type) {
    case `INCREMENT_SLIDE_OPACITY`:
      return {
        opacity: action.opacity >= 0 ? action.opacity : 0.25,
      }
    case `SET_CURRENT_SLIDER_INDEX`:
      return {
        currentIndex: action.index,
      }
    default:
      return initialState
  }
}

const initialState = {
  opacity: 0.25,
  currentIndex: null,
}

export default FeaturedContentSlideState