const transitionDefaultTimeout = 750

const PageTransitionState = (state = initialState, action) => {
  switch (action.type) {
    case `START_TRANSITION`:
      return {
        ...state,
        ...{
          active: true,
          inTransition: true,
        },
      }
    case `END_TRANSITION`:
      return {
        ...state,
        ...{
          inTransition: false,
        },
      }
    case `SET_TRANSITION_TYPE`:
      return {
        ...state,
        ...{
          transitionType: action.transitionType,
          transitionTimeout: action.transitionTimeout
            ? action.transitionTimeout
            : transitionDefaultTimeout,
        },
      }
    case `RESET_TRANSITION`:
      return initialState
    default:
      return state
  }
}

const initialState = {
  inTransition: false,
  active: false,
  transitionTimeout: transitionDefaultTimeout,
  transitionType: `fade`,
}

export default PageTransitionState
