import { combineReducers } from "redux"
import NavState from "./NavState"
import SiteHeaderState from "./SiteHeaderState"
import FeaturedContentSlideState from "./FeaturedContentSlideState"
import SearchState from "./SearchState"
import PageTransitionState from "./PageTransitionState"

export default combineReducers({
  NavState,
  SiteHeaderState,
  FeaturedContentSlideState,
  SearchState,
  PageTransitionState,
})
