import {
  NavMainOpenStateClassName,
  BurgerActiveClassName,
} from "../utils/variables"

const NavState = (state = initialState, action) => {
  switch (action.type) {
    case `OPEN_NAV`:
      return {
        closed: false,
        className: NavMainOpenStateClassName,
        burgerClassName: BurgerActiveClassName,
      }
    case `CLOSE_NAV`:
      return initialState
    default:
      return state
  }
}

const initialState = {
  closed: true,
  className: ``,
  burgerClassName: ``,
}

export default NavState
