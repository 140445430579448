import {
  SiteHeaderInvertedStateClassName,
  SiteHeaderHiddenStateClassName,
  BurgerInvertedClassName,
} from "../utils/variables"

const SiteHeaderState = (state = initialState, action) => {
  switch (action.type) {
    case `INVERT_HEADER`:
      return {
        inverted: true,
        className: SiteHeaderInvertedStateClassName,
        burgerClassName: BurgerInvertedClassName,
      }
    case `HIDE_HEADER`:
      return {
        ...state,
        ...{
          className: state.inverted
            ? `${SiteHeaderInvertedStateClassName} ${SiteHeaderHiddenStateClassName}`
            : SiteHeaderHiddenStateClassName,
        },
      }
    case `SHOW_HEADER`:
      return {
        ...state,
        ...{
          className: state.inverted ? SiteHeaderInvertedStateClassName : ``,
        },
      }
    case `RESET_HEADER`:
      return initialState
    default:
      return state
  }
}

const initialState = {
  inverted: false,
  className: ``,
  burgerClassName: ``,
}

export default SiteHeaderState
