const SearchState = (state = [], action) => {
  switch (action.type) {
    case `SET_SEARCH_STRING`:
      return {
        searchString: action.searchString,
        hasSearchResults: action.hasSearchResults,
        reset: false,
        isSearchPage: action.isSearchPage,
      }
    case `RESET_SEARCH`:
      return {
        searchString: ``,
        hasSearchResults: false,
        reset: true,
        isSearchPage: false,
      }
    case `INITIAL_SEARCH`:
      return initialState
    default:
      return state
  }
}

const initialState = {
  searchString: ``,
  hasSearchResults: false,
  reset: false,
  isSearchPage: false,
}

export default SearchState
